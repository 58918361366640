import React from 'react'
import { Link } from 'react-router-dom'

export default function PageNotFound() {
  return (
    <div className='justify-center items-center text-center relative pt-16'>
        <div className="table-cell align-middle w-screen not-found-height px-auto py-16">
          <p className='playfair font-bold mb-8 text-3xl'>404: Page Not Found!</p>
          <Link to='/' className='bg-green-500 hover:bg-green-400 focus:outline-none focus:bg-green-400 px-5 py-3 border border-transparent text-base font-medium rounded-md text-white transition duration-150 ease-in-out'>Return Home</Link>
        </div>
    </div>
  )
}
