import { ArrowRightIcon } from '@heroicons/react/outline'
import React from 'react'
import FillerImg from '../../images/filler.jpg'
import Pfp from '../../images/pfp.jpg'

export default function FeaturedPost({ post }) {
  let { author } = post;
  post = post.post;
  let dateStr = new Date(post.pubDate).toDateString().substring(4)
  dateStr = dateStr.split(' ')
  dateStr = `${dateStr[0]} ${dateStr[1]}, ${dateStr[2]}`
  return (
    <a href={post.link} target="_blank" rel="noreferrer">
      <div className='grid grid-cols-1 md:grid-cols-2 gap-4 rounded-xl full-shadow border overflow-hidden p-0 border-gray-300 scale-101 mb-8'>
        <img src={post.thumbnail || FillerImg} alt={post.title} className='object-cover w-full h-full' />
        <div className="p-6 md:my-auto">
          <div className='mb-4'>
            <p className=" shadow-sm text-center text-sm inline tracking-wider text-white bg-green-500 items-center px-3 py-0.5 rounded-full font-medium">
              {dateStr}
            </p>
          </div>
          <h1 className='text-3xl playfair font-bold'>{post.title}</h1>
          <div className="flex vertical-middle items-center mt-4">
            <img src={author.image.includes('https://cdn-images-1.medium.com/proxy/') ? Pfp : author.image} alt={post.author}  className="h-8 w-8 mr-2 rounded-full" />
            <p className="text-gray-400 text-sm">{post.author}</p>
          </div>
          <div className='cursor-pointer r-arrow-hover hover:text-green-500 inline-block'>
            <p className='inline-flex align-middle mt-8 border-b-2 pb-2'>
              Read Post <ArrowRightIcon className="ml-2 h-6 w-6 arrow" aria-hidden="true" />
            </p>
          </div>
        </div>
      </div>
    </a>
  )
}
