import React, { useState } from 'react'
import Hero from '../components/Hero'
import { HiMail } from "react-icons/hi"
import { FaInstagram, FaMedium } from 'react-icons/fa'
import emailjs from 'emailjs-com'
require('dotenv').config()

export default function Contact() {
  const icons = [
    {
      label: "Email.",
      icon: HiMail,
      link: `mailto:hearourvoicescachs@gmail.com`,
    },
    {
      label: "Instagram",
      icon: FaInstagram,
      link: `https://www.instagram.com/hearourvoicescachs/`,
    },
    {
      label: "Medium",
      icon: FaMedium,
      link: `https://angelinarichter.medium.com/`,
    },
  ]
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')

  const handleSubmit = e => {
    e.preventDefault()
    let templateParams = {
      from_name: email,
      sender_name: name,
      to_name: 'hearourvoicescachs@gmail.com',
      subject: subject,
      message: message,
    }
    // console.log(templateParams)
    // let params = {
    //   process.env.REACT_APP_EMAILJS_SERVICEID,
    //   process.env.REACT_APP_EMAILJS_TEMPLATEID,
    //   templateParams,
    //   process.env.REACT_APP_EMAILJS_USERID,
    // }
    // console.log(params)
    // console.log(process.env.REACT_APP_EMAILJS_SERVICEID)
    emailjs.send(
      'service_3hy3dlu',
      'template_lrs0pln',
      templateParams,
      'user_FzVMPuexmUq8KEpgDmGPV',
    ).then((result) => {
      setStatus('Message sent successfully! We will get back to you as soon as possible.')
      setName('')
      setEmail('')
      setSubject('')
      setMessage('')
    }).catch((error) => {
      setStatus('Error: unable to deliver message.')
      console.log(error);
    });
  }

  return (
    <div>
      <Hero src="https://static.wixstatic.com/media/aa951b_631da5c9b1d9413685ff4a7f7d6530f6~mv2_d_4051_2590_s_4_2.jpg/v1/fill/w_899,h_567,al_c,q_85,usm_0.66_1.00_0.01/IMG_2586_JPG.webp" text="Contact" />
      <div className='max-w-screen-xl mx-auto py-10 px-4 sm:px-6'>
        <div className="grid grid-cols-1 md:grid-cols-5 gap-6">
          <div className="md:col-span-2 px-4 py-5">
            <h1 className='playfair text-4xl font-semibold mb-4'>Send a Message</h1>
            <p className='text-gray-700 text-sm'>If you prefer sending an email, send us an email at <a className='underline font-medium' href='mailto:hearourvoicescachs@gmail.com'>hearourvoicescachs@gmail.com</a>!</p>
            <div className="flex mr-6 mt-4 text-gray-500">
              {icons.map(icon => (
                <FooterLink href={icon.link} icon={icon.icon} label={icon.label} />
              ))}
            </div>
          </div>
          <form className='md:col-span-3' action="#" method="POST" onSubmit={handleSubmit}>
            <div className="shadow sm:rounded-md sm:overflow-hidden">
              <div className="px-4 py-5 bg-gray-100 space-y-6 sm:p-6">
                <div className="">
                  <label className="block font-semibold text-gray-700">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    autoComplete="name"
                    required
                    placeholder='John Doe'
                    className="mt-2 focus:ring-green-400 focus:ring-1 focus:border-green-400 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md outline-none px-3 py-2"
                  />
                </div>
                <div className="mt-4">
                  <label className="block font-semibold text-gray-700">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    autoComplete="email"
                    required
                    placeholder='johndoe@email.com'
                    className="mt-2 focus:ring-green-400 focus:ring-1 focus:border-green-400 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md outline-none px-3 py-2"
                  />
                </div>
                <div className="mt-4">
                  <label className="block font-semibold text-gray-700">
                    Subject
                  </label>
                  <input
                    type="text"
                    id="subject"
                    value={subject}
                    onChange={e => setSubject(e.target.value)}
                    autoComplete="subject"
                    required
                    placeholder='Idea for Potential Collaboration'
                    className="mt-2 focus:ring-green-400 focus:ring-1 focus:border-green-400 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md outline-none px-3 py-2"
                  />
                </div>
                <div>
                  <label className="block font-semibold text-gray-700">
                    Message
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="message"
                      rows={3}
                      value={message}
                      onChange={e => setMessage(e.target.value)}
                      className="shadow-sm focus:ring-green-400 focus:ring-1 focus:border-green-400 mt-2 block w-full sm:text-sm border border-gray-300 rounded-md outline-none px-3 py-2"
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                  Submit
                </button>
                { status && (<p className='text-gray-700 mt-1 text-sm italic'>{status}</p>) }
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

const FooterLink = ({ href, label, icon: Icon }) => {
  return (
    <div className="inline-block mr-6">
      <a
        href={href}
        target="_blank"
        rel="noreferrer noopener"
        className="hover:text-green-500 transition duration-150 ease-in-out"
      >
        <span className="sr-only">{label}</span>
        <Icon className="w-6 h-6 fill-current" />
      </a>
    </div>
  )
}
