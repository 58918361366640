import React, { useEffect, useState } from 'react'
import Hero from '../components/Hero'
import FeaturedPost from '../components/blog/FeaturedPost'
import BlogPost from '../components/blog/BlogPost'
import Loader from '../images/loading.gif'

export default function Blog() {
  const [loading, setLoading] = useState(true);
  const [blogPosts, setBlogPosts] = useState([]);

  const getPosts = async () => {
    let oldData = await fetch(
      "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40angelinarichter"
    );
    oldData = await oldData.json();

    let newData = await fetch(
      "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40derekyliu1"
    );
    newData = await newData.json();

    const bp = [];
    newData.items.forEach(i => {
      bp.push({
        post: i,
        author: newData.feed
      })
    });

    oldData.items.forEach(i => {
      bp.push({
        post: i,
        author: oldData.feed
      })
    });

    setBlogPosts(bp);
    setLoading(false);
  }

  useEffect(() => {
    getPosts();
  }, []);

  return (
    <div>
      <Hero src="https://static.wixstatic.com/media/aa951b_631da5c9b1d9413685ff4a7f7d6530f6~mv2_d_4051_2590_s_4_2.jpg/v1/fill/w_899,h_567,al_c,q_85,usm_0.66_1.00_0.01/IMG_2586_JPG.webp" text="Blog" />
      <div className='mt-10 max-w-screen-lg mx-auto py-10 px-4 sm:px-6'>
        <p className="mb-8 text-center text-green-600 max-w-prose mx-auto text-3xl font-bold playfair">
          Latest Posts
        </p>
        {loading ? (
          <div
            className="flex items-center justify-center"
            style={{ height: "70vh" }}
          >
            <img src={Loader} alt="Blog Posts" />
          </div>
        ) : (
          <>
            { blogPosts.slice(0, 1).map((post, i) => {
                return (
                  <FeaturedPost post={post} key={post.post.link} />
                )
              }) }
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-8'>

              { blogPosts.slice(1)?.map((post, i) => {
                return (
                  <BlogPost post={post} key={post.post.link} />
                )
              }) }
            </div>
            <p className='mt-8 text-center'>Want to read more? Follow our <a href="https://angelinarichter.medium.com" target="_blank" rel="noreferrer" className='hover:text-green-500 underline'>Medium page</a> to view all of our blog posts!</p>
          </>
        )}
      </div>
    </div>
  )
}
