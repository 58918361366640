import { ChevronDownIcon, ArrowRightIcon } from '@heroicons/react/outline'
import React, { useRef } from 'react'
import { Link } from 'react-router-dom';
import MissionImg from '../images/mission.jpg'

export default function Home() {
  const scrollRef = useRef(null);
  const executeScroll = () => scrollRef.current.scrollIntoView()
  return (
    <div>
      <div className='min-h-screen home-bg text-white text-center justify-center items-center pt-16 relative'>
        <div className="table-cell align-middle w-screen home-hero-height">
          <div className='mx-auto max-w-screen-xl items-center justify-center px-4 inline-block sm:px-6'>
            <h1 className='playfair text-8xl font-extrabold'>Hear <span className='text-shadow-green'>Our</span> Voices</h1>
            <div className='uppercase tracking-wider mt-4 text-md mb-3'>
              <p>a <span className='inline text-transparent bg-clip-text bg-gradient-to-r from-green-200 to-green-400'>chinese american community health services</span> chapter</p>
            </div>
            <h3 className='text-xl'>We focus on youth mental health in the Washington Metropolitan Area.</h3>
            <p className="text-xs max-w-2xl p-1 sm:p-5">
              We have been awarded a <strong>regional suicide prevention grant</strong> from the Virginia Department of Behavioral Health and Developmental Services, which we are using to continue filming and producing mental health documentaries in the future.
            </p>
          </div>
        </div>
        <div className="absolute center-trick bottom-12 h-12 fill-current text-center text-white">
            <p>Watch our latest documentary here!</p>

          <ChevronDownIcon onClick={executeScroll} className="h-8 w-8 mt-4 mx-auto block cursor-pointer arrow-hover-animation text-white" aria-hidden="true" />
        </div>
      </div>
      <div ref={scrollRef} className='max-w-screen-xl mx-auto py-10 pt-20 px-4 sm:px-6'>
        <p className="text-center text-green-600 max-w-prose mx-auto text-xl sm:text-2xl">
        Our documentary film series focuses on retelling the <strong>emotional journeys of teenagers</strong> in hopes of uplifting those in the <strong>youth community already experiencing mental health disorders</strong>.
        </p>
        <div className="mt-16 max-w-screen-lg mx-auto rounded-lg overflow-hidden shadow-md">
          {/* <p className="text-center uppercase inline tracking-wider text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-green-600">
            Featured
          </p> */}
          <div className='relative w-full h-0' style={{paddingBottom: '56.25%'}}>
            <iframe
              src="https://www.youtube.com/embed/btzjmfU6wYM"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className='absolute top-0 left-0 w-full h-full'
            >
            </iframe>
          </div>
          <div className="bg-green-100 p-8 min-w-full">
            <p className="text-center uppercase inline tracking-wider bg-white text-green-500 items-center px-3 py-0.5 rounded-full text-sm font-medium">
              Featured
            </p>
            <div className='flex flex-col md:flex-row min-w-full'>
              <div className='md:mr-8 mb-2'>
                <h2 className='mt-2 text-3xl playfair font-bold'>
                  A Day In Her Life
                </h2>
              </div>
              <div className="text-md italic flex-1">
                In this video, we follow around an Asian American high-school student for a day and talk about questions like how growing up in this area or how COVID-19 has impacted her mental health.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-gray-500 text-white'>
        <div className='max-w-screen-xl mx-auto py-10 px-4 sm:px-6 flex flex-col lg:flex-row min-w-full'>
          <div className="p-6 flex-1">
            <p className="shadow-sm text-center uppercase inline tracking-wider bg-white text-green-600 items-center px-3 py-0.5 rounded-full text-base font-medium">
              Our Mission
            </p>
            <p className='mt-4'>
              We are a youth-led chapter of the CACHS that aims to bring to light the struggles of teenagers who experience mental health illnesses through writing, film, and community events.
            </p>
            <Link to='/about' className='cursor-pointer r-arrow-hover hover:text-green-300'>
              <p className='inline-flex align-middle mt-6 border-b-2 pb-2'>
                About Us <ArrowRightIcon className="ml-2 h-6 w-6 arrow" aria-hidden="true" />
              </p>
            </Link>
          </div>
          <img className='lg:w-1/2 rounded-md shadow-md' src={MissionImg} alt="Teen Mental Health - Hear Our Voices (A CACHS Chapter)" />
        </div>
      </div>
    </div>
  )
}
