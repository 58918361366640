import React from "react"
import { HiMail } from "react-icons/hi"
import { FaInstagram, FaMedium } from 'react-icons/fa'
import { Link } from "react-router-dom"
import Logo from '../images/cachs_logo.webp'

const Footer = () => {
  const icons = [
    {
      label: "Email",
      icon: HiMail,
      link: `mailto:hearourvoicescachs@gmail.com`,
    },
    {
      label: "Instagram",
      icon: FaInstagram,
      link: `https://www.instagram.com/hearourvoicescachs/`,
    },
    {
      label: "Medium",
      icon: FaMedium,
      link: `https://angelinarichter.medium.com/`,
    },
  ]
  const navigation = [
    {
      text: 'Home',
      path: '/',
    },
    {
      text: 'About Us',
      path: '/about',
    },
    {
      text: 'Blog',
      path: '/blog',
    },
    {
      text: 'Videos',
      path: '/videos',
    },
    {
      text: 'Events',
      path: '/events',
    },
    {
      text: 'Contact',
      path: '/contact',
    },
  ]

  return (
    <footer className="flex-shrink-0">
      <div className="bg-gray-100">
        <div className="max-w-screen-xl justify-center mx-auto py-8 px-4 sm:px-20 sm:flex sm:justify-between text-gray-500">
          <div className='sm:order-2 text-md text-center sm:text-left'>
            <p className='tracking-wide font-bold mb-4 text-lg'>PAGES</p>
            <div className="grid grid-cols-2 gap-x-6 gap-y-4">
              {navigation.map(link => (
                <Link className='hover:text-green-500' to={link.path} key={link.text}>{link.text}</Link>
              ))}
            </div>
          </div>
          <div className="mt-8 sm:mt-0 sm:order-1 text-center justify-center">
            <div className="flex flex-col sm:flex-row">
              <a href="https://www.cachs.org/" target="_blank" rel="noreferrer">
                <img className='justify-center mx-auto mb-2 h-32 sm:mr-4' src={Logo} alt="Hear Our Voices: A CACHS Chapter" />
              </a>
              <div className='align-middle mx-auto my-auto justify-start max-w-footertext'>
                <p className="text-base leading-6 md:mr-4 lg:mr-0">
                  &copy; {new Date().getFullYear()} Hear Our Voices. All rights reserved.
                </p>
                <div className="flex ml-6 mt-2 justify-center">
                  {icons.map(icon => (
                    <FooterLink href={icon.link} icon={icon.icon} label={icon.label} key={icon.link} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

const FooterLink = ({ href, label, icon: Icon }) => {
  return (
    <div className="inline-block mr-6">
      <a
        href={href}
        target="_blank"
        rel="noreferrer noopener"
        className="hover:text-green-500 transition duration-150 ease-in-out"
      >
        <span className="sr-only">{label}</span>
        <Icon className="w-6 h-6 fill-current" />
      </a>
    </div>
  )
}

export default Footer