import React, { useState } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import Logo from '../images/cachs_logo.webp'

export default function Navbar() {
  const navigation = [
    {
      text: 'About Us',
      path: '/about',
    },
    {
      text: 'Blog',
      path: '/blog',
    },
    {
      text: 'Videos',
      path: '/videos',
    },
    {
      text: 'Events',
      path: '/events',
    },
    {
      text: 'Contact',
      path: '/contact',
    },
  ]
  const [isOpen, setIsOpen] = useState(false)

  return (
    <nav className="bg-white shadow fixed inset-x-0 top-0 z-30">
      <div className="max-w-7xl mx-auto px-4 md:px-8">
        <div className="flex justify-between md:justify-start h-16">
          <Link
              to="/"
              className="flex-shrink-0 flex items-center text-xl font-extrabold playfair"
            >
              <img className="w-10 h-10 mr-2" src={Logo} alt="Hear Our Voices: A CACHS Chapter" />
              <h3 className='nav-title'>Hear Our Voices</h3>
            </Link>
          <div className="flex justify-end md:justify-start items-center">
            <div className="hidden md:ml-8 md:flex space-x-8">
              {navigation.map(link => (
                <NavLink
                  exact={true}
                  to={link.path}
                  key={link.path}
                  className="inline-flex items-center px-1 pt-1 text-sm font-medium leading-5 text-gray-500 hover:text-green-500 focus:outline-none focus:text-green-500 transition duration-150 ease-in-out"
                  activeClassName="inline-flex items-center px-1 pt-1 text-sm font-medium leading-5 text-green-500 hover:text-green-500 focus:text-green-500 focus:outline-none transition duration-150 ease-in-out"
                >
                  {link.text}
                </NavLink>
              ))}
            </div>
          </div>
          <div className="-mr-2 flex items-center md:hidden">
            {/* Mobile menu button */}
            <button
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
              aria-label="Main menu"
              aria-expanded="false"
              onClick={() => setIsOpen(!isOpen)}
            >
              {!isOpen && (
                <MenuIcon className="block h-6 w-6" aria-hidden="true" />
              )}

              {isOpen && (
                <XIcon className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>

      <div className={(isOpen ? "block" : "hidden") + " md:hidden"}>
        <div className="pt-2 pb-3 space-y-1">
          {navigation.map(link => (
            <NavLink
              exact={true}
              to={link.path}
              key={link.path}
              className="block pl-3 pr-4 py-2 text-base font-medium text-gray-600 hover:text-white hover:bg-green-500 focus:outline-none focus:text-white focus:bg-green-500 transition duration-150 ease-in-out"
              activeClassName="block pl-3 pr-4 py-2 text-base font-medium white bg-green-500 focus:outline-none focus:text-white focus:bg-green-500 transition duration-150 ease-in-out"
            >
              {link.text}
            </NavLink>
          ))}
        </div>
      </div>
    </nav>
  )
}
