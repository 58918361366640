import React from 'react'
import Hero from '../components/Hero'
import Event1 from '../images/events/event1.png'
import Event2 from '../images/events/event2.png'
import Event3 from '../images/events/event3.png'

export default function Events() {
  const events = [
    {
      name: "August Mental Health Discussion",
      date: "8/1/2021",
      img: Event1,
      description: "Our first student-led mental health discussion focused on 4 major topics - youth experience with mental health, social media's impact on mental health, school and testing's impact on mental health, and mental health from the perspective of the LGBTQ+ community.",
    },
    {
      name: "Youth Mental Health First Aid Training",
      date: "11/13/2021",
      img: Event2,
      description: (
        <p>Youth Mental Health First Aid Training given by the Asian American Health Initiative (AAHI) for AAPI parents residing in Montgomery County, Maryland. Training will be held on Zoom on 11/13/21, 10AM-4PM (ET), and on 12/18/21, 10AM-4PM (ET). Register now via <a href="https://tinyurl.com/4r5vhsvk" className='hover:text-green-500 underline'>https://tinyurl.com/4r5vhsvk</a>!</p>
      ),
    },
    {
      name: "Dreams of My Parents Essay Contest",
      date: "Spring 2022",
      img: Event3,
      description: (
        <p>In Spring 2022, the Calvin J. Li Memorial Foundation will be holding an Essay Contest entitled "Dreams of My Parents" in hopes of bridging the cultural gap between the immigrant parents and their American-born children, and promoting a more supportive social and familiar environment for our children. The Foundation will work with various community organizations to promote this Essay Contest for high school students from families of Asian-Pacific backgrounds to cultivate an intergenerational understanding of heritage and initiate an open dialogue on identity. The deadline to submit is <b>April 18, 2022</b>. Please find more info by clicking this link: <a href="https://tinyurl.com/yc33mz7n" className='hover:text-green-500 underline'>https://tinyurl.com/yc33mz7n</a>.</p>
      )
    }
  ].reverse()
  return (
    <div>
      <Hero src="https://static.wixstatic.com/media/aa951b_631da5c9b1d9413685ff4a7f7d6530f6~mv2_d_4051_2590_s_4_2.jpg/v1/fill/w_899,h_567,al_c,q_85,usm_0.66_1.00_0.01/IMG_2586_JPG.webp" text="Events" />
      <div className='max-w-screen-xl mt-10 mx-auto py-10 px-4 sm:px-6'>
        <p className="text-center text-green-600 max-w-prose mx-auto text-3xl font-bold playfair">
          Past Events
        </p>
        <p className='max-w-screen-lg mx-auto text-center text-lg text-gray-600 mt-4'>
          To carry out our mission of helping teens deal with mental health issues and sharing their stories in a safe place for all, we frequently host mental-health related events that are available to everyone.
        </p>
      </div>
      <div className="bg-white">
        <div className='max-w-screen-xl mx-auto pb-10 pt-4 px-4 sm:px-6 grid grid-cols-1 gap-y-10'>
          {events.map(event => {
            return (
              <div className="p-0 grid grid-cols-1 md:grid-cols-2 rounded-lg shadow overflow-hidden h-full bg-gray-100">
                <img src={event.img} alt={event.name} className='m-0 object-cover w-full h-full' />
                <div className="p-6 pt-10 md:my-auto md:pt-6">
                  <div className='mb-4'>
                    <p className="shadow text-center text-sm inline tracking-wider text-white bg-green-500 items-center px-3 py-0.5 rounded-full font-medium">
                      {event.date}
                    </p>
                  </div>
                  <h1 className='text-3xl playfair font-bold'>{event.name}</h1>
                  <p className='text-base mt-6 text-gray-500'>{event.description}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
