import React from 'react'

export default function Hero({ src, text }) {
  return (
    <div>
      <div className='min-h-hero bg-cover bg-no-repeat bg-center overflow-hidden text-white text-center justify-center items-center pt-16' style={{backgroundImage: `url("https://wallpaperaccess.com/full/1155007.jpg"), linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6))`, backgroundBlendMode: 'overlay'}}>
        <div className="table-cell align-middle w-screen height-hero">
          <div className='mx-auto max-w-screen-xl items-center justify-center px-4 inline-block sm:px-6'>
            <h1 className='playfair text-8xl font-extrabold'>{text}</h1>
          </div>
        </div>
      </div>
    </div>
  )
}
