import React from 'react'
import Hero from '../components/Hero'
import { CheckIcon } from '@heroicons/react/outline'
import BackgroundImg from '../images/homebg.jpg'

export default function About() {
  const missions = [
    "Helping students of varying ages deal with mental health issues and stress stemming from their environments.",
    "Increasing awareness of struggles issues in both our community and beyond.",
    "Reflecting on the youth community by sharing their stories.",
    "Collaborated with students from: Singapore, France, Turkey, Kazakhstan, Belize, Indonesia, Argentina, Sudan, Afganistan, and more." 
  ]
  
   const coreTeam = [
    {
      name: "Angelina Richter",
      img: require('../images/team/Screen Shot 2022-12-01 at 9.15.39 AM.png').default,
      bio: "Angelina is a 17-year old senior attending Thomas Jefferson High School for Science and Technology. Her interests mainly lie in the STEM area, but she also loves reading and writing. Angelina enjoys attending her school's Threshold Magazine and Varsity Math clubs, as well as the Latin Club, Honor Council and Orchestra gatherings.",
      role: "President, Director of Writing, & Director of Film",
    },
    {
      name: "Kai Bian",
      img: require('../images/team/katie.jpg').default,
      bio: "Hi there! I'm Kai! I'm a senior and in school, some of my favorite subjects are math and art. Outside of school, I like playing Genshin Impact, writing, and making music.",
      role: "President",
    },
    {
      name: "Sritan Motati",
      img: require('../images/team/sritan.jpg').default,
      bio: "Sritan is a senior at Thomas Jefferson High School for Science and Technology. He is interested in health, computer science, and their intersection and serves as the Captain of his school's Machine Learning Club, Biology Olympiad Club, and Bioinformatics Society. At Hear Our Voices, Sritan manages all technology and boosts our digital presence to bring our initiatives to a wider audience.",
      role: "Head of Tech",
    },
  ]
  const otherTeam = [
    {
      name: "Andrew Zhou",
      img: require('../images/team/andrew.jpg').default,
      bio: "Hi, I'm Andrew, and I'm a senior at TJHSST. I think mental health is super important because the mind is what makes us human; yet, the mind can be so very delicate. I look forward to this journey and hope we can all learn and grow in new ways. In my spare time, I like to read, run, and make music.",
    },
  ]
  return (
    <div>
      <Hero src={BackgroundImg} text="About Us" />
      <div className='mt-10 max-w-screen-xl mx-auto py-10 px-4 sm:px-6'>
        <p className="text-center text-green-600 max-w-prose mx-auto text-3xl font-bold playfair">
          Inspire. Connect. Reflect.
        </p>
        <p className='max-w-screen-lg mx-auto text-center text-lg text-gray-600 mt-4'>Our goal is to increase awareness of teenage mental health issues, uplift those who are vulnerable in the youth mental health community, and share the stories of teenagers who have struggled with mental health in the past.</p>
        <div className="mt-6 max-w-screen-lg mx-auto grid grid-cols-1 md:grid-cols-3 gap-4">
          {missions.map(mission => {
            return (
              <div className="p-4 grid grid-cols-7 shadow border border-gray-300 rounded-lg">
                <CheckIcon className="my-auto align-left sm:my-0 col-start-1 col-end-2 h-9/12 max-h-10 max-w-10 w-9/12 text-green-500" />
                <p className='ml-2 my-auto sm:my-0 col-start-2 col-end-8 font-bold text-md'>{mission}</p>
              </div>
            )
          })}
        </div>
      </div>
      <div className='max-w-screen-xl mx-auto py-10 px-4 sm:px-6 bg-gray-100'>
        <p className="text-4xl font-extrabold playfair text-center">
          Core Team
        </p>
        <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {coreTeam.map(member => {
            return (
              <div className="rounded shadow flex flex-col overflow-hidden bg-white">
                <img className='h-96 object-cover' src={member.img} alt={member.name} />
                <div className="p-6 text-center">
                  <p className="text-2xl playfair font-bold">
                    {member.name}
                  </p>
                  <p className="text-green-500 text-sm mt-1 mb-3">{member.role}</p>
                  <p className="text-gray-500 text-left">{member.bio}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className='max-w-screen-xl mx-auto py-10 px-4 sm:px-6'>
        <p className="text-4xl font-extrabold playfair text-center">
          Team Members
        </p>
        <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {otherTeam.map(member => {
            return (
              <div className="rounded shadow flex flex-col overflow-hidden bg-gray-100">
                <img className='h-96 object-cover' src={member.img} alt={member.name} />
                <div className="p-6 text-center">
                  <p className="text-2xl playfair font-bold">
                    {member.name}
                  </p>
                  <p className="text-gray-500 text-left mt-3 text-sm">{member.bio}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
