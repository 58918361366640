import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Navbar from './components/Navbar';
import Footer from './components/Footer'
import ScrollToTop from './components/ScrollToTop'
import Home from './views/Home'
import Blog from './views/Blog'
import Videos from './views/Videos'
import Events from './views/Events'
import Contact from './views/Contact'
import About from './views/About'
import PageNotFound from './views/PageNotFound'
// import UnderConstruction from './views/UnderConstruction';

function App() {
  return (
    <div className="app">
      <Router className='flex flex-col min-h-screen'>
        <Navbar />
        <div className='flex-grow'>
          <ScrollToTop />
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/about' component={About} />
            <Route exact path='/blog' component={Blog} />
            <Route exact path='/videos' component={Videos} />
            <Route exact path='/events' component={Events} />
            <Route exact path='/contact' component={Contact} />
            <Route component={PageNotFound} />
          </Switch>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
