import React from 'react'
import Hero from '../components/Hero'
import VideoData from '../data/videos.json'

export default function Videos() {
  // video link: https://www.youtube.com/watch?v={code}
  // image link: http://i3.ytimg.com/vi/{code}/maxresdefault.jpg
  const videos = [...VideoData].reverse()
  return (
    <div>
      <Hero src="https://static.wixstatic.com/media/aa951b_631da5c9b1d9413685ff4a7f7d6530f6~mv2_d_4051_2590_s_4_2.jpg/v1/fill/w_899,h_567,al_c,q_85,usm_0.66_1.00_0.01/IMG_2586_JPG.webp" text="Videos" />
      <div className='max-w-screen-xl mt-10 mx-auto py-10 px-4 sm:px-6'>
        <p className="text-center text-green-600 max-w-prose mx-auto text-3xl font-bold playfair">
          Recorded Videos
        </p>
        <p className='max-w-screen-lg mx-auto text-center text-lg text-gray-600 mt-4'>
          Hear Our Voices posts recordings of past events and discussions as well as conversations with important guest speakers on YouTube. Here are some of our featured videos.
        </p>
        <div className="mt-10 max-w-screen-xl gap-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mx-auto">
          {videos.map(video => {
            return (
              <div className="flex flex-col">
                <div className='relative w-full h-0' style={{paddingBottom: '56.25%'}}>
                  <iframe
                    src={`https://www.youtube.com/embed/${video.code}`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowFullScreen
                    className='absolute top-0 left-0 w-full h-full'
                  >
                  </iframe>
                </div>
                <a className='text-gray-600 hover:text-gray-500 text-sm' href={`https://www.youtube.com/watch?v=${video.code}`} target="_blank" rel='noreferrer'><p className='font-bold mt-3'>{video.name}</p></a>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
